@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,500;1,600&family=Poppins:wght@800&display=swap');
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  text-align: justify;
  box-sizing: border-box;
}
h1,
p {
  font-family: "Montserrat", sans-serif;
  font-size:1rem;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color:red;
}

ul {
    list-style: none;
    
}

a{
  color:red
}

#collasible-nav-dropdown {
  margin-left:15px;
  margin-right:15px;
  text-align: left;
  @media (max-width: 768px) {
    margin:0;
    padding:0;
    padding-top:9px;
    padding-bottom:5px;
  }
}

#collasible-nav-dropdown{
  color:azure;
}

#basic-navbar-nav{
  color:azure;
}


.carousel-item img{
  height: calc(100vh);
  object-fit: cover;
}
.carousel-caption p{
 font-size:26px;
 text-align: left;
 font-family: "Montserrat", sans-serif;
font-weight: 400;
}
.carousel-caption h3{
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size:36px;
  text-align: left;
  
 }

 #logo {
  font-family: "Poppins", sans-serif;
  font-size:22px;
  margin-top:14px;
  color:#ffffff;

 }

 .input-field {
  width:400px;
  border: none;
 }

 h3{
  font-family: "Montserrat", sans-serif;
  font-weight: 1rem;
 }


 .carousel-img{
width:100%;
max-height: 300px;;
object-fit:cover
 }
.comp-info{
  background-color: red;
  width:100%;
}

.comp-description{
  font-size: 14px;;
}
.card {
  padding:0;
  margin:0;
}
